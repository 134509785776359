import { useCallback } from 'react';

const useBrowserStorage = () => {
  const getLocalStorageItem = useCallback((itemName: string) => {
    if (typeof window !== 'undefined') {
      const localStorageItem = localStorage.getItem(itemName);
      return typeof localStorageItem == 'string'
        ? JSON.parse(localStorageItem)
        : localStorageItem;
    }
  }, []);

  const setSessionStorageItem = useCallback(
    (itemName: string, itemValue: string | { [key: string]: string }) => {
      if (typeof window !== 'undefined')
        sessionStorage.setItem(itemName, JSON.stringify(itemValue));
    },
    [],
  );

  const removeSessionStorageItem = useCallback((itemName: string) => {
    if (typeof window !== 'undefined') sessionStorage.removeItem(itemName);
  }, []);

  const getSessionStorageItem = useCallback((itemName: string) => {
    if (typeof window !== 'undefined') {
      const sessionStorageItem = sessionStorage.getItem(itemName);
      return typeof sessionStorageItem == 'string'
        ? JSON.parse(sessionStorageItem)
        : sessionStorageItem;
    }
  }, []);

  const setLocalStorageItem = useCallback(
    (itemName: string, itemValue: string | { [key: string]: string }) => {
      if (typeof window !== 'undefined')
        localStorage.setItem(itemName, JSON.stringify(itemValue));
    },
    [],
  );

  const removeLocalStorageItem = useCallback((itemName: string) => {
    if (typeof window !== 'undefined') localStorage.removeItem(itemName);
  }, []);

  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
  };
};

export { useBrowserStorage };
